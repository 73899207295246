import { useState } from 'react';
import type { NotifyError } from '../../types';
import ScreenRouter from '../generic/ScreenRouter';
import translations from '../../translations';
import { AnomalyDashboardProvider } from '../anomalyDetection/AnomalyProvider';
import AnomalySummary from './insightsSummary/AnomalySummary';
import AnomalyDashboard from '../anomalyDetection/AnomalyDashboard';
import UsageSummary from './insightsSummary/UsageSummary';
import ErrorsSummary from './insightsSummary/ErrorsSummary';
import ErrorsInsights from './insightsDetailed/ErrorsInsights';
import { InsightsProvider } from './InsightsProvider';
import UsageInsights from './insightsDetailed/UsageInsights';
import StatesInsights from './insightsDetailed/StatesInsights';
import type { FlowUsageDashboardResponse } from '../../sources/dashboard';
import type { StateResponseAPI } from '../../types/states';
import ConnectorInvokerRequestInsights from './insightsDetailed/ConnectorInvokerRequestInsights';

import './developer-dashboard.less';

export enum View {
    summary = 0,
    usage = 1,
    anomaly = 2,
    error = 3,
    states = 4,
    connectorInvokerRequests = 5,
}

interface Props {
    notifyError: NotifyError;
}

const InsightsDashboard = ({ notifyError }: Props) => {
    const [currentView, setCurrentView] = useState<View>(View.summary);
    const [flowUsage, setFlowUsage] = useState<FlowUsageDashboardResponse | null>(null);
    const [stateDetails, setStateDetails] = useState<StateResponseAPI | null>(null);

    const SummaryDashboardView = (_: { screen?: string }) => (
        <>
            <span className="title-bar">
                <h1>{translations.DASHBOARD_header}</h1>
            </span>
            <div className="insights-content">
                <UsageSummary notifyError={notifyError} setCurrentView={setCurrentView} />
                <ErrorsSummary setCurrentView={setCurrentView} />
                <AnomalySummary setCurrentView={setCurrentView} />
            </div>
        </>
    );

    const UsageInsightsDetailed = (_: { screen?: string }) => (
        <UsageInsights
            notifyError={notifyError}
            setCurrentView={(view: View, flowUsage: FlowUsageDashboardResponse | null) => {
                setCurrentView(view);
                setFlowUsage(flowUsage);
            }}
        />
    );

    const ErrorsInsightsDetailed = (_: { screen?: string }) => (
        <ErrorsInsights setCurrentView={setCurrentView} notifyError={notifyError} />
    );

    const StatesInsightsDetailed = (_: {
        screen?: string;
        flowUsage: FlowUsageDashboardResponse | null;
        setStateDetails: (stateDetails: StateResponseAPI | null) => void;
    }) =>
        _.flowUsage && _.setStateDetails ? (
            <StatesInsights
                setCurrentView={setCurrentView}
                flowUsage={_.flowUsage}
                notifyError={notifyError}
                setStateDetails={_.setStateDetails}
            />
        ) : null;

    const ConnectorInvokerRequestsInsightsDetailed = (_: {
        screen?: string;
        flowUsage: FlowUsageDashboardResponse | null;
        stateDetails: StateResponseAPI | null;
        setStateDetails: (stateDetails: StateResponseAPI | null) => void;
    }) =>
        _.flowUsage && _.stateDetails && _.setStateDetails ? (
            <ConnectorInvokerRequestInsights
                flowUsage={_.flowUsage}
                stateDetails={_.stateDetails}
                setStateDetails={_.setStateDetails}
                setCurrentView={setCurrentView}
                setFlowUsage={setFlowUsage}
                notifyError={notifyError}
            />
        ) : null;

    return (
        <div className="admin-page">
            <AnomalyDashboardProvider notifyError={notifyError} initialDateIndex={0}>
                <InsightsProvider notifyError={notifyError}>
                    <ScreenRouter currentScreen={currentView.toString()}>
                        <SummaryDashboardView screen={View.summary.toString()} />
                        <UsageInsightsDetailed screen={View.usage.toString()} />
                        <AnomalyDashboard
                            screen={View.anomaly.toString()}
                            setCurrentView={setCurrentView}
                        />
                        <ErrorsInsightsDetailed screen={View.error.toString()} />
                        <StatesInsightsDetailed
                            screen={View.states.toString()}
                            flowUsage={flowUsage}
                            setStateDetails={setStateDetails}
                        />
                        <ConnectorInvokerRequestsInsightsDetailed
                            screen={View.connectorInvokerRequests.toString()}
                            flowUsage={flowUsage}
                            stateDetails={stateDetails}
                            setStateDetails={setStateDetails}
                        />
                    </ScreenRouter>
                </InsightsProvider>
            </AnomalyDashboardProvider>
        </div>
    );
};

export default InsightsDashboard;
