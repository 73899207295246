import { type ChangeEventHandler, type ComponentProps, useState } from 'react';
import { type ConnectedProps, connect } from 'react-redux';
import '../../../../css/organization-invite-form.less';
import { sendUserInvitation } from '../../../js/actions/reduxActions/organizations';
import { GUID_REQUIRED_LENGTH } from '../../constants';
import translations from '../../translations';
import { getEmailValidityMessage, getIdValidityMessage } from '../../utils/validation';
import FormGroup from '../generic/FormGroup';
import GenericModal from '../generic/modal/GenericModal';

type UserInviteProps = {
    closeModal: () => void;
    show: boolean;
} & ConnectedProps<typeof connector> &
    Pick<ComponentProps<typeof GenericModal>, 'container'>;

const connector = connect(null, {
    sendUserInvitation,
});

const UserInvite = ({ show, container, sendUserInvitation, closeModal }: UserInviteProps) => {
    const [useEmailInput, setUseEmailInput] = useState(true);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [input, setInput] = useState('');

    const validateInput = (input: string) => {
        const message = useEmailInput
            ? getEmailValidityMessage(input)
            : getIdValidityMessage(input);

        setValidationMessage(message);

        return message.length === 0;
    };

    const handleInputChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setInput(value);

        if (!hasSubmitted) {
            return;
        }

        validateInput(value);
    };

    const handleInputTypeChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
        setUseEmailInput(value === 'email');
        resetForm(false);
    };

    const handleSendInvite = () => {
        setHasSubmitted(true);
        if (!validateInput(input)) {
            return;
        }
        sendUserInvitation({
            inviteUserData: {
                [useEmailInput ? 'email' : 'id']: input,
            },
        });
        resetForm();
    };

    const resetForm = (resetMode = true) => {
        setHasSubmitted(false);
        setValidationMessage('');
        setInput('');
        if (resetMode) {
            setUseEmailInput(true);
        }
    };

    const handleClose = () => {
        resetForm();
        closeModal();
    };

    return (
        <GenericModal
            show={show}
            container={container}
            title={translations.FORG_invite_user_title}
            onHide={handleClose}
            renderBody={() => (
                <>
                    <span className="help-block">
                        {translations.FORG_user_invite_form_help_text}
                    </span>
                    <FormGroup
                        label={
                            useEmailInput
                                ? translations.FORG_user_invite_form_user_email_input_label
                                : translations.FORG_user_invite_form_user_id_input_label
                        }
                        htmlFor="user-invite-input"
                        isRequired
                        isValid={validationMessage.length === 0}
                        validationMessage={validationMessage}
                        showValidation={hasSubmitted}
                    >
                        <input
                            id="user-invite-input"
                            className="form-control"
                            maxLength={useEmailInput ? 255 : GUID_REQUIRED_LENGTH}
                            onChange={handleInputChange}
                            value={input}
                        />
                    </FormGroup>
                    <div>
                        <label className="organization-invite-radio-button-container">
                            <input
                                onChange={handleInputTypeChange}
                                type="radio"
                                value="email"
                                name="input-type"
                                defaultChecked
                            />
                            <span className="organization-invite-radio-button-label">
                                {translations.FORG_user_invite_form_use_email_radio_label}
                            </span>
                        </label>
                        <label className="organization-invite-radio-button-container">
                            <input
                                onChange={handleInputTypeChange}
                                type="radio"
                                value="id"
                                name="input-type"
                            />
                            <span className="organization-invite-radio-button-label">
                                {translations.FORG_user_invite_form_use_id_radio_label}
                            </span>
                        </label>
                    </div>
                    <span className="help-block">
                        {useEmailInput
                            ? translations.FORG_user_invite_form_email_help_text
                            : translations.FORG_invite_form_id_help_text}
                    </span>
                </>
            )}
            renderFooter={() => (
                <>
                    <button type="button" className="btn btn-sm btn-default" onClick={handleClose}>
                        {translations.FORG_invite_form_cancel_button_label}
                    </button>
                    <button
                        type="button"
                        className="btn btn-sm btn-success"
                        onClick={handleSendInvite}
                    >
                        {translations.FORG_invite_form_send_button_label}
                    </button>
                </>
            )}
        />
    );
};

export default connector(UserInvite);
